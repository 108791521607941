import 'cookieconsent/src/cookieconsent';
import 'picturefill';
import 'lazysizes';
import 'webfontloader';
import {Foundation} from 'foundation-sites/js/foundation.core';
import {Reveal} from 'foundation-sites/js/foundation.reveal';
import {ResponsiveAccordionTabs} from 'foundation-sites/js/foundation.responsiveAccordionTabs';
import 'what-input';

import 'scss/styles.js';

/**
 * Body
 *
 * @author HDNET GmbH & Co. KG
 * @todo Refactor modules
 */
(($, window, document) => {
	const $document = $(document);
	const $window = $(window);

	const modulePromiseResolver = ({default: Module}) => {
		new Module;

		return new Promise(resolve => resolve(Module));
	};

	$document.ready(() => {
		import(/* webpackChunkName: 'module-lightbox' */'./Modules/_lightbox').then(modulePromiseResolver);
		import(/* webpackChunkName: 'module-anchorlink' */'./Modules/_anchorlink').then(modulePromiseResolver);
		import(/* webpackChunkName: 'module-form' */'./Modules/_form').then(modulePromiseResolver);
		import(/* webpackChunkName: 'module-menu' */'./Modules/_menu').then(modulePromiseResolver);
		import(/* webpackChunkName: 'module-slider' */'./Modules/_slider').then(modulePromiseResolver).then((Slider) => {
			new Slider('.js-stage-slider');
		});

		if ($('.v-hello').length > 0) {
			import(/* webpackChunkName: 'vue-hello' */'./HelloApp');
		}

		/**
		 * Temporary way to kill the native form validation of browsers as it's
		 * buggy.
		 */
		$('form').attr('novalidate', 'novalidate');
	});

	/**
	 * Initialize foundation.
	 */
	Foundation.addToJquery($);
	Foundation.plugin(Reveal, 'Reveal');
	Foundation.plugin(ResponsiveAccordionTabs, 'ResponsiveAccordionTabs');
	$document.foundation();

	/**
	 * Lazy loading images.
	 */
	$document.on('lazyloaded', function (e) {
		$(e.target).closest('.lazy-picture').addClass('imagesloaded');
	});

	/**
	 * Listen for the modals to open and focus
	 * the search field whenever the search modal
	 * opens.
	 */
	$document.on('open.zf.reveal', function () {
		$(this).find('#globalSearchWord').focus();
	});

	/**
	 * Wait until window is loaded.
	 */
	$window.on('load', () => {
		/**
		 * Initalize cookieconsent.
		 */
		$('<div id=\'container\'>').appendTo('body');
		window.cookieconsent.initialise({
			'content': {
				'message': Site.Configuration.translate.cookieconsent.message,
				'dismiss': Site.Configuration.translate.cookieconsent.dismiss,
				'link': Site.Configuration.translate.cookieconsent.link,
				'href': Site.Configuration.targets.cookieconsent.href,
			},
			'palette': {
				'popup': {
					'background': '#3a4751',
					'text': '#ffffff',
				},
				'button': {
					'background': '#E2097D',
				},
			},
			'position': 'bottom-right',
			'container': document.getElementById('container'),
		});
	});
})(jQuery, window, document);

if (module.hot) {
	module.hot.accept();
}
